<template>
	<el-dialog title="百分比设定" :close-on-click-modal="false" :visible.sync="visible" width="1000px">
		<el-form :model="dataForm" :rules="dataRule" :inline="true" ref="dataForm" label-width="100px" class="border-form">
			<el-row class="set_border">
				<el-col :span="9" class="col-set_border">
					<el-form-item label="百分比文字" class="item">
						<el-input v-model="dataForm.proPerName" placeholder="请输入" style="width:210px">
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="9" class="col-set_border1">
					<el-form-item label="百分比数值" class="item">
						<el-input v-model="dataForm.proPerValue" placeholder="请输入" style="width:210px">
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="4" style="float: right;">
					<el-form-item>
						<el-button v-preventReClick class="btn" type="primary" size="small"
							@click="dataFormSubmit()">保存</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row style="margin-top:20px;">
			<el-table ref="dataListTable" class="dataListTable" :data="dataList" header-cell-class-name="dataListTHeader"
				border stripe header-align="center" style="width: 100%; max-height: 500px;"
				:cell-style="{ padding: '0px' }">
				<el-table-column width="80" type="index" align="center">
				</el-table-column>
				<el-table-column prop="proPerName" label="百分比文字" align="center">
				</el-table-column>
				<el-table-column prop="proPerValue" label="百分比数值" align="center">
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-button v-preventReClick type="text" size="small"
							@click="addOrUpdateHandle(scope.row.recNo, scope.row.proPerName, scope.row.proPerValue)">修改
						</el-button>
						<el-button v-preventReClick type="text" size="small"
							@click="deleteHandle(scope.row.recNo)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-row>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">关闭</el-button>
		</el-row>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "progressPercentage-setting",
	data() {
		return {
			visible: false,
			inline: false,
			dataList: [],
			dataForm: {
				recNo: "",
				proPerValue: "",
				proPerName: "",
				kindType: "",
				workNo: $common.getItem("workNo"),
			},
			dataRule: {},
		}
	},
	methods: {
		init(type) {
			this.visible = true;
			this.dataForm = {
				recNo: "",
				proPerValue: "",
				proPerName: "",
				kindType: "",
				workNo: $common.getItem("workNo"),
			},
				this.dataForm.kindType = type ? type : 0;
			this.$nextTick(() => {
				if (this.dataForm.kindType) {
					this.$http({
						url: this.$store.state.httpUrl + '/business/progresspercentageinfo/list',
						method: 'get',
						params: this.$http.adornParams({
							workNo: $common.getItem("workNo"),
						})
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.dataList = data.body.records;
						}
					})
				}
			})
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/progresspercentageinfo/save";
					if (this.dataForm.recNo && this.dataForm.recNo != 0) {
						_url = "/business/progresspercentageinfo/update";
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.dataForm
					}).then(({
						data
					}) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.init(this.dataForm.kindType);
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		},
		//删除
		deleteHandle(id) {
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + `/business/progresspercentageinfo/delete/`,
					method: 'post',
					data: this.$http.adornData([id], false),
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.init(this.dataForm.kindType)
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				});
			})
		},
		addOrUpdateHandle(id, name, value) {
			this.dataForm.recNo = id;
			this.dataForm.proPerName = name;
			this.dataForm.proPerValue = value;

		},
	}
}
</script>
<style scoped>
::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

/* .border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
} */

/* .border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
	border-left: solid #dadada 1px;
} */

.border-form .el-row.set_border .col-set_border {
	border: solid #dadada 1px;
}

.border-form .el-row.set_border .col-set_border1 {
	border: solid #dadada 1px;
	border-left: solid #dadada 0px;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: 1px solid #DADADA;
}
</style>
